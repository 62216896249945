// @flow
import React, {PureComponent, type ElementConfig} from 'react';

import {LinkContext} from './LinkContext';

export type LinkProps = {
  ...ElementConfig<'a'>,
  // nextjs-only props
  // todo: figure out how to get rid of this property
  as?: string,
};

// defaults to an <a/> tag; to override all links, add:
//
//   <LinkContext.Provider value={CustomLinkComponent}>
//     // ...
//   </LinkContext.Provider>
//
// as an ancestor
//
// note: must accept `ref` prop, so can't be a function
// component
export class Link extends PureComponent<LinkProps> {
  render() {
    return (
      <LinkContext.Consumer>
        {Component => <Component {...this.props} />}
      </LinkContext.Consumer>
    );
  }
}
