// @flow
import {createContext, type ComponentType} from 'react';

import {type LinkProps} from './Link';

// abstract link component to be implemented by react-router-dom, next/link, etc
export type LinkComponent = ComponentType<LinkProps>;

const DefaultLink: LinkComponent = ('a': any);

export const LinkContext = createContext<LinkComponent>(DefaultLink);
