// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

import * as cwn_user from '../../../cwn/user';
import * as cwn_ubnt_unifi from '../../../cwn/ubnt/unifi';
import * as cwn_tech_viewmodels from '../../../cwn/tech/viewmodels';
import * as cwn_tech from '../../../cwn/tech';
import * as cwn_site_hw from '../../../cwn/site/hw';
import * as cwn_site_details from '../../../cwn/site/details';
import * as cwn_site from '../../../cwn/site';
import * as cwn_search from '../../../cwn/search';
import * as cwn_rpc from '../../../cwn/rpc';
import * as cwn_radios from '../../../cwn/radios';
import * as cwn_photos from '../../../cwn/photos';
import * as cwn_lead from '../../../cwn/lead';
import * as cwn_health from '../../../cwn/health';
import * as cwn_han from '../../../cwn/han';
import * as cwn_geo from '../../../cwn/geo';
import * as cwn_eero from '../../../cwn/eero';
import * as cwn_devices from '../../../cwn/devices';
import * as cwn_customers from '../../../cwn/customers';
import * as cwn_antenna from '../../../cwn/antenna';

// START cwn/tech/server/service.proto

class HealthCheckReply {
  diagnoses: Array<cwn_health.Diagnosis>;

  constructor(props: $Shape<HealthCheckReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.diagnoses = [];
    if (props.hasOwnProperty('diagnoses')) {
      const v = props.diagnoses;
      if (!Array.isArray(v)) {
        throw new Error('repeated field diagnoses should be array');
      }
      this.diagnoses = v.map(function(v) {
        return new cwn_health.Diagnosis(v);
      });
    }
  }
}
export {HealthCheckReply};

class GetUNMSConfigReply {
  config_text: string;

  constructor(props: $Shape<GetUNMSConfigReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.config_text = '';
    if (props.hasOwnProperty('config_text')) {
      const v = props.config_text;
      this.config_text = v;
    }
  }
}
export {GetUNMSConfigReply};

class WorkOrderDetails_PlanDetails {
  name: string;
  speed: string;

  constructor(props: $Shape<WorkOrderDetails_PlanDetails> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.speed = '0';
    if (props.hasOwnProperty('speed')) {
      const v = props.speed;
      this.speed = v;
    }
  }
}
export {WorkOrderDetails_PlanDetails};
class WorkOrderDetails {
  work_order: ?cwn_tech.FlexibleWorkOrder;
  tasks: Array<cwn_tech_viewmodels.TaskViewModel>;
  site: ?cwn_site.Site;
  lead: ?cwn_lead.Lead;
  customer: ?cwn_customers.Customer;
  plan_details: ?WorkOrderDetails_PlanDetails;
  time_window: boolean;

  constructor(props: $Shape<WorkOrderDetails> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_order = null;
    if (props.hasOwnProperty('work_order')) {
      const v = props.work_order;
      this.work_order = v && new cwn_tech.FlexibleWorkOrder(v);
    }

    this.tasks = [];
    if (props.hasOwnProperty('tasks')) {
      const v = props.tasks;
      if (!Array.isArray(v)) {
        throw new Error('repeated field tasks should be array');
      }
      this.tasks = v.map(function(v) {
        return new cwn_tech_viewmodels.TaskViewModel(v);
      });
    }

    this.site = null;
    if (props.hasOwnProperty('site')) {
      const v = props.site;
      this.site = v && new cwn_site.Site(v);
    }

    this.lead = null;
    if (props.hasOwnProperty('lead')) {
      const v = props.lead;
      this.lead = v && new cwn_lead.Lead(v);
    }

    this.customer = null;
    if (props.hasOwnProperty('customer')) {
      const v = props.customer;
      this.customer = v && new cwn_customers.Customer(v);
    }

    this.plan_details = null;
    if (props.hasOwnProperty('plan_details')) {
      const v = props.plan_details;
      this.plan_details = v && new WorkOrderDetails_PlanDetails(v);
    }

    this.time_window = false;
    if (props.hasOwnProperty('time_window')) {
      const v = props.time_window;
      this.time_window = !!v;
    }
  }

  getWorkOrder(): cwn_tech.FlexibleWorkOrder {
    if (this.work_order) {
      return this.work_order;
    }
    return new cwn_tech.FlexibleWorkOrder();
  }

  getSite(): cwn_site.Site {
    if (this.site) {
      return this.site;
    }
    return new cwn_site.Site();
  }

  getLead(): cwn_lead.Lead {
    if (this.lead) {
      return this.lead;
    }
    return new cwn_lead.Lead();
  }

  getCustomer(): cwn_customers.Customer {
    if (this.customer) {
      return this.customer;
    }
    return new cwn_customers.Customer();
  }

  getPlanDetails(): WorkOrderDetails_PlanDetails {
    if (this.plan_details) {
      return this.plan_details;
    }
    return new WorkOrderDetails_PlanDetails();
  }
}
export {WorkOrderDetails};

class ShotsForWorkOrderResponse {
  work_order_id: string;
  shots: Array<cwn_tech_viewmodels.WorkOrderShotViewModel>;

  constructor(props: $Shape<ShotsForWorkOrderResponse> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_order_id = '0';
    if (props.hasOwnProperty('work_order_id')) {
      const v = props.work_order_id;
      this.work_order_id = v;
    }

    this.shots = [];
    if (props.hasOwnProperty('shots')) {
      const v = props.shots;
      if (!Array.isArray(v)) {
        throw new Error('repeated field shots should be array');
      }
      this.shots = v.map(function(v) {
        return new cwn_tech_viewmodels.WorkOrderShotViewModel(v);
      });
    }
  }
}
export {ShotsForWorkOrderResponse};

class SearchWorkOrdersResponse {
  work_orders: Array<WorkOrderDetails>;
  cursor: string;

  constructor(props: $Shape<SearchWorkOrdersResponse> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_orders = [];
    if (props.hasOwnProperty('work_orders')) {
      const v = props.work_orders;
      if (!Array.isArray(v)) {
        throw new Error('repeated field work_orders should be array');
      }
      this.work_orders = v.map(function(v) {
        return new WorkOrderDetails(v);
      });
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {SearchWorkOrdersResponse};

class UpdateTaskReq {
  work_order_id: string;
  task: ?cwn_tech.Task;
  work_unit: ?cwn_tech.WorkUnit;

  constructor(props: $Shape<UpdateTaskReq> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_order_id = '0';
    if (props.hasOwnProperty('work_order_id')) {
      const v = props.work_order_id;
      this.work_order_id = v;
    }

    this.task = null;
    if (props.hasOwnProperty('task')) {
      const v = props.task;
      this.task = v && new cwn_tech.Task(v);
    }

    this.work_unit = null;
    if (props.hasOwnProperty('work_unit')) {
      const v = props.work_unit;
      this.work_unit = v && new cwn_tech.WorkUnit(v);
    }
  }

  getTask(): cwn_tech.Task {
    if (this.task) {
      return this.task;
    }
    return new cwn_tech.Task();
  }

  getWorkUnit(): cwn_tech.WorkUnit {
    if (this.work_unit) {
      return this.work_unit;
    }
    return new cwn_tech.WorkUnit();
  }
}
export {UpdateTaskReq};

class CloseWorkOrderRequest {
  id: string;
  reasons: Array<cwn_tech.FlexibleWorkOrder_ClosedReason>;
  other_reason: string;
  closing_notes: string;
  shots_without_los: Array<cwn_tech_viewmodels.WorkOrderShotViewModel>;

  constructor(props: $Shape<CloseWorkOrderRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.reasons = [];
    if (props.hasOwnProperty('reasons')) {
      const v = props.reasons;
      if (!Array.isArray(v)) {
        throw new Error('repeated field reasons should be array');
      }
      this.reasons = v.map(function(v) {
        return v;
      });
    }

    this.other_reason = '';
    if (props.hasOwnProperty('other_reason')) {
      const v = props.other_reason;
      this.other_reason = v;
    }

    this.closing_notes = '';
    if (props.hasOwnProperty('closing_notes')) {
      const v = props.closing_notes;
      this.closing_notes = v;
    }

    this.shots_without_los = [];
    if (props.hasOwnProperty('shots_without_los')) {
      const v = props.shots_without_los;
      if (!Array.isArray(v)) {
        throw new Error('repeated field shots_without_los should be array');
      }
      this.shots_without_los = v.map(function(v) {
        return new cwn_tech_viewmodels.WorkOrderShotViewModel(v);
      });
    }
  }
}
export {CloseWorkOrderRequest};

class ActivateLeadRequest {
  id: string;
  send_email: boolean;

  constructor(props: $Shape<ActivateLeadRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.send_email = false;
    if (props.hasOwnProperty('send_email')) {
      const v = props.send_email;
      this.send_email = !!v;
    }
  }
}
export {ActivateLeadRequest};

class UpdatedSiteRadioLink {
  id: string;
  radio_mac_address: string;

  constructor(props: $Shape<UpdatedSiteRadioLink> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.radio_mac_address = '';
    if (props.hasOwnProperty('radio_mac_address')) {
      const v = props.radio_mac_address;
      this.radio_mac_address = v;
    }
  }
}
export {UpdatedSiteRadioLink};

class UpdateSiteRadioLinksRequest {
  srls: Array<UpdatedSiteRadioLink>;

  constructor(props: $Shape<UpdateSiteRadioLinksRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.srls = [];
    if (props.hasOwnProperty('srls')) {
      const v = props.srls;
      if (!Array.isArray(v)) {
        throw new Error('repeated field srls should be array');
      }
      this.srls = v.map(function(v) {
        return new UpdatedSiteRadioLink(v);
      });
    }
  }
}
export {UpdateSiteRadioLinksRequest};

class CheckAddApPrerequisitesCompleteResponse {
  complete: boolean;

  constructor(
    props: $Shape<CheckAddApPrerequisitesCompleteResponse> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.complete = false;
    if (props.hasOwnProperty('complete')) {
      const v = props.complete;
      this.complete = !!v;
    }
  }
}
export {CheckAddApPrerequisitesCompleteResponse};

class CheckCollectBillingCompleteResponse {
  complete: boolean;

  constructor(props: $Shape<CheckCollectBillingCompleteResponse> = {}): void {
    if (!props) {
      props = {};
    }

    this.complete = false;
    if (props.hasOwnProperty('complete')) {
      const v = props.complete;
      this.complete = !!v;
    }
  }
}
export {CheckCollectBillingCompleteResponse};

class GetSignedURLReq {
  filename: string;
  content_type: string;

  constructor(props: $Shape<GetSignedURLReq> = {}): void {
    if (!props) {
      props = {};
    }

    this.filename = '';
    if (props.hasOwnProperty('filename')) {
      const v = props.filename;
      this.filename = v;
    }

    this.content_type = '';
    if (props.hasOwnProperty('content_type')) {
      const v = props.content_type;
      this.content_type = v;
    }
  }
}
export {GetSignedURLReq};

class GetSignedURLRes {
  signed_url: string;
  public_url: string;
  photo_id: string;

  constructor(props: $Shape<GetSignedURLRes> = {}): void {
    if (!props) {
      props = {};
    }

    this.signed_url = '';
    if (props.hasOwnProperty('signed_url')) {
      const v = props.signed_url;
      this.signed_url = v;
    }

    this.public_url = '';
    if (props.hasOwnProperty('public_url')) {
      const v = props.public_url;
      this.public_url = v;
    }

    this.photo_id = '';
    if (props.hasOwnProperty('photo_id')) {
      const v = props.photo_id;
      this.photo_id = v;
    }
  }
}
export {GetSignedURLRes};

class Photos {
  photos: Array<cwn_photos.Photo>;

  constructor(props: $Shape<Photos> = {}): void {
    if (!props) {
      props = {};
    }

    this.photos = [];
    if (props.hasOwnProperty('photos')) {
      const v = props.photos;
      if (!Array.isArray(v)) {
        throw new Error('repeated field photos should be array');
      }
      this.photos = v.map(function(v) {
        return new cwn_photos.Photo(v);
      });
    }
  }
}
export {Photos};

class SendBillingInfoRequestEmailRequest {
  lead_id: string;
  email: string;

  constructor(props: $Shape<SendBillingInfoRequestEmailRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }

    this.email = '';
    if (props.hasOwnProperty('email')) {
      const v = props.email;
      this.email = v;
    }
  }
}
export {SendBillingInfoRequestEmailRequest};

class FindAllQBPsWithinBoundsRequest {
  minLat: number;
  maxLat: number;
  minLon: number;
  maxLon: number;
  hashes: Array<string>;

  constructor(props: $Shape<FindAllQBPsWithinBoundsRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.minLat = 0;
    if (props.hasOwnProperty('minLat')) {
      const v = props.minLat;
      this.minLat = v;
    }

    this.maxLat = 0;
    if (props.hasOwnProperty('maxLat')) {
      const v = props.maxLat;
      this.maxLat = v;
    }

    this.minLon = 0;
    if (props.hasOwnProperty('minLon')) {
      const v = props.minLon;
      this.minLon = v;
    }

    this.maxLon = 0;
    if (props.hasOwnProperty('maxLon')) {
      const v = props.maxLon;
      this.maxLon = v;
    }

    this.hashes = [];
    if (props.hasOwnProperty('hashes')) {
      const v = props.hashes;
      if (!Array.isArray(v)) {
        throw new Error('repeated field hashes should be array');
      }
      this.hashes = v.map(function(v) {
        return v;
      });
    }
  }
}
export {FindAllQBPsWithinBoundsRequest};

class FindAllQBPsWithinBoundsResponse {
  points: Array<ElevationPoint>;
  hashes: Array<string>;

  constructor(props: $Shape<FindAllQBPsWithinBoundsResponse> = {}): void {
    if (!props) {
      props = {};
    }

    this.points = [];
    if (props.hasOwnProperty('points')) {
      const v = props.points;
      if (!Array.isArray(v)) {
        throw new Error('repeated field points should be array');
      }
      this.points = v.map(function(v) {
        return new ElevationPoint(v);
      });
    }

    this.hashes = [];
    if (props.hasOwnProperty('hashes')) {
      const v = props.hashes;
      if (!Array.isArray(v)) {
        throw new Error('repeated field hashes should be array');
      }
      this.hashes = v.map(function(v) {
        return v;
      });
    }
  }
}
export {FindAllQBPsWithinBoundsResponse};

class ElevationPoint {
  lat: number;
  lon: number;
  elevation: number;

  constructor(props: $Shape<ElevationPoint> = {}): void {
    if (!props) {
      props = {};
    }

    this.lat = 0;
    if (props.hasOwnProperty('lat')) {
      const v = props.lat;
      this.lat = v;
    }

    this.lon = 0;
    if (props.hasOwnProperty('lon')) {
      const v = props.lon;
      this.lon = v;
    }

    this.elevation = 0;
    if (props.hasOwnProperty('elevation')) {
      const v = props.elevation;
      this.elevation = v;
    }
  }
}
export {ElevationPoint};

class GetMountResponse {
  mount: ?cwn_geo.Mount;

  constructor(props: $Shape<GetMountResponse> = {}): void {
    if (!props) {
      props = {};
    }

    this.mount = null;
    if (props.hasOwnProperty('mount')) {
      const v = props.mount;
      this.mount = v && new cwn_geo.Mount(v);
    }
  }

  getMount(): cwn_geo.Mount {
    if (this.mount) {
      return this.mount;
    }
    return new cwn_geo.Mount();
  }
}
export {GetMountResponse};

class UpdateMountRequest {
  id: string;
  type: cwn_geo.Mount_Type;
  lat: number;
  long: number;
  height_above_roof: number;

  constructor(props: $Shape<UpdateMountRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.type = cwn_geo.Mount_TypeValue(0);
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this.lat = 0;
    if (props.hasOwnProperty('lat')) {
      const v = props.lat;
      this.lat = v;
    }

    this.long = 0;
    if (props.hasOwnProperty('long')) {
      const v = props.long;
      this.long = v;
    }

    this.height_above_roof = 0;
    if (props.hasOwnProperty('height_above_roof')) {
      const v = props.height_above_roof;
      this.height_above_roof = v;
    }
  }
}
export {UpdateMountRequest};

class UpdateBuildingDescriptionRequest {
  site_id: string;
  two_person: boolean;
  forty_foot_ladder: boolean;
  roof_material: cwn_site_details.BuildingDescription_RoofMaterial;
  access: string;
  note: string;

  constructor(props: $Shape<UpdateBuildingDescriptionRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.two_person = false;
    if (props.hasOwnProperty('two_person')) {
      const v = props.two_person;
      this.two_person = !!v;
    }

    this.forty_foot_ladder = false;
    if (props.hasOwnProperty('forty_foot_ladder')) {
      const v = props.forty_foot_ladder;
      this.forty_foot_ladder = !!v;
    }

    this.roof_material = cwn_site_details.BuildingDescription_RoofMaterialValue(
      0,
    );
    if (props.hasOwnProperty('roof_material')) {
      const v = props.roof_material;
      this.roof_material = v;
    }

    this.access = '';
    if (props.hasOwnProperty('access')) {
      const v = props.access;
      this.access = v;
    }

    this.note = '';
    if (props.hasOwnProperty('note')) {
      const v = props.note;
      this.note = v;
    }
  }
}
export {UpdateBuildingDescriptionRequest};

class UpdateEquipmentDeviceRequest {
  site_id: string;
  device: cwn_site_hw.Equipment_Device;

  constructor(props: $Shape<UpdateEquipmentDeviceRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.device = cwn_site_hw.Equipment_DeviceValue(0);
    if (props.hasOwnProperty('device')) {
      const v = props.device;
      this.device = v;
    }
  }
}
export {UpdateEquipmentDeviceRequest};

class AssignSiteDeviceRequest {
  site_id: string;
  device_id: string;

  constructor(props: $Shape<AssignSiteDeviceRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }
  }
}
export {AssignSiteDeviceRequest};

class SearchDevicesResponse {
  devices: Array<cwn_devices.Device>;

  constructor(props: $Shape<SearchDevicesResponse> = {}): void {
    if (!props) {
      props = {};
    }

    this.devices = [];
    if (props.hasOwnProperty('devices')) {
      const v = props.devices;
      if (!Array.isArray(v)) {
        throw new Error('repeated field devices should be array');
      }
      this.devices = v.map(function(v) {
        return new cwn_devices.Device(v);
      });
    }
  }
}
export {SearchDevicesResponse};

class GetHanForSubscriptionResponse {
  han: ?cwn_han.Han;

  constructor(props: $Shape<GetHanForSubscriptionResponse> = {}): void {
    if (!props) {
      props = {};
    }

    this.han = null;
    if (props.hasOwnProperty('han')) {
      const v = props.han;
      this.han = v && new cwn_han.Han(v);
    }
  }

  getHan(): cwn_han.Han {
    if (this.han) {
      return this.han;
    }
    return new cwn_han.Han();
  }
}
export {GetHanForSubscriptionResponse};

class CreateHanRequest {
  subscription_id: string;
  customer_id: string;

  constructor(props: $Shape<CreateHanRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.subscription_id = '0';
    if (props.hasOwnProperty('subscription_id')) {
      const v = props.subscription_id;
      this.subscription_id = v;
    }

    this.customer_id = '';
    if (props.hasOwnProperty('customer_id')) {
      const v = props.customer_id;
      this.customer_id = v;
    }
  }
}
export {CreateHanRequest};

class UpdateHanRequest {
  han_id: string;
  ap_macs: Array<string>;
  wlans: Array<cwn_han.Wlan>;
  eero_serial_numbers: Array<string>;
  force_reassignment: boolean;

  constructor(props: $Shape<UpdateHanRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.han_id = '0';
    if (props.hasOwnProperty('han_id')) {
      const v = props.han_id;
      this.han_id = v;
    }

    this.ap_macs = [];
    if (props.hasOwnProperty('ap_macs')) {
      const v = props.ap_macs;
      if (!Array.isArray(v)) {
        throw new Error('repeated field ap_macs should be array');
      }
      this.ap_macs = v.map(function(v) {
        return v;
      });
    }

    this.wlans = [];
    if (props.hasOwnProperty('wlans')) {
      const v = props.wlans;
      if (!Array.isArray(v)) {
        throw new Error('repeated field wlans should be array');
      }
      this.wlans = v.map(function(v) {
        return new cwn_han.Wlan(v);
      });
    }

    this.eero_serial_numbers = [];
    if (props.hasOwnProperty('eero_serial_numbers')) {
      const v = props.eero_serial_numbers;
      if (!Array.isArray(v)) {
        throw new Error('repeated field eero_serial_numbers should be array');
      }
      this.eero_serial_numbers = v.map(function(v) {
        return v;
      });
    }

    this.force_reassignment = false;
    if (props.hasOwnProperty('force_reassignment')) {
      const v = props.force_reassignment;
      this.force_reassignment = !!v;
    }
  }
}
export {UpdateHanRequest};

export const UpdateHanResponse_StatusValues = {
  OK: 0,
  CONFLICT: 1,
};
type UpdateHanResponse_Status = $Keys<typeof UpdateHanResponse_StatusValues>;
export type {UpdateHanResponse_Status};

export function UpdateHanResponse_StatusValue(
  n: number,
): UpdateHanResponse_Status {
  switch (n) {
    case 0:
      return 'OK';

    case 1:
      return 'CONFLICT';

    default:
      return 'OK';
  }
}

class UpdateHanResponse_Conflict {
  sub_id: string;
  name: string;
  address: string;
  han_id: string;

  constructor(props: $Shape<UpdateHanResponse_Conflict> = {}): void {
    if (!props) {
      props = {};
    }

    this.sub_id = '0';
    if (props.hasOwnProperty('sub_id')) {
      const v = props.sub_id;
      this.sub_id = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.address = '';
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v;
    }

    this.han_id = '0';
    if (props.hasOwnProperty('han_id')) {
      const v = props.han_id;
      this.han_id = v;
    }
  }
}
export {UpdateHanResponse_Conflict};
class UpdateHanResponse {
  status: UpdateHanResponse_Status;
  conflicts: {[key: string]: ?UpdateHanResponse_Conflict};
  han: ?cwn_han.Han;

  constructor(props: $Shape<UpdateHanResponse> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = UpdateHanResponse_StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.conflicts = {};
    if (props.hasOwnProperty('conflicts')) {
      const v = props.conflicts;
      for (const key: any of Object.keys(v)) {
        this.conflicts[key] = new UpdateHanResponse_Conflict((v[key]: any));
      }
    }

    this.han = null;
    if (props.hasOwnProperty('han')) {
      const v = props.han;
      this.han = v && new cwn_han.Han(v);
    }
  }

  getHan(): cwn_han.Han {
    if (this.han) {
      return this.han;
    }
    return new cwn_han.Han();
  }
}
export {UpdateHanResponse};

class UpdateDeclineApRequest {
  han_id: string;
  declined_ap: boolean;
  declined_ap_mac: string;

  constructor(props: $Shape<UpdateDeclineApRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.han_id = '0';
    if (props.hasOwnProperty('han_id')) {
      const v = props.han_id;
      this.han_id = v;
    }

    this.declined_ap = false;
    if (props.hasOwnProperty('declined_ap')) {
      const v = props.declined_ap;
      this.declined_ap = !!v;
    }

    this.declined_ap_mac = '';
    if (props.hasOwnProperty('declined_ap_mac')) {
      const v = props.declined_ap_mac;
      this.declined_ap_mac = v;
    }
  }
}
export {UpdateDeclineApRequest};

class GetLastUnifiStatusesRequest {
  macs: Array<string>;

  constructor(props: $Shape<GetLastUnifiStatusesRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.macs = [];
    if (props.hasOwnProperty('macs')) {
      const v = props.macs;
      if (!Array.isArray(v)) {
        throw new Error('repeated field macs should be array');
      }
      this.macs = v.map(function(v) {
        return v;
      });
    }
  }
}
export {GetLastUnifiStatusesRequest};

class GetLastUnifiStatusesResponse {
  status_by_mac: {[key: string]: ?cwn_ubnt_unifi.Status};

  constructor(props: $Shape<GetLastUnifiStatusesResponse> = {}): void {
    if (!props) {
      props = {};
    }

    this.status_by_mac = {};
    if (props.hasOwnProperty('status_by_mac')) {
      const v = props.status_by_mac;
      for (const key: any of Object.keys(v)) {
        this.status_by_mac[key] = new cwn_ubnt_unifi.Status((v[key]: any));
      }
    }
  }
}
export {GetLastUnifiStatusesResponse};

export const GetLatestRadioStatusReply_RangeTypeValues = {
  UNKNOWN_RANGE_TYPE: 0,
  TxPowerDbm: 1,
  MCS: 2,
};
type GetLatestRadioStatusReply_RangeType = $Keys<
  typeof GetLatestRadioStatusReply_RangeTypeValues,
>;
export type {GetLatestRadioStatusReply_RangeType};

export function GetLatestRadioStatusReply_RangeTypeValue(
  n: number,
): GetLatestRadioStatusReply_RangeType {
  switch (n) {
    case 0:
      return 'UNKNOWN_RANGE_TYPE';

    case 1:
      return 'TxPowerDbm';

    case 2:
      return 'MCS';

    default:
      return 'UNKNOWN_RANGE_TYPE';
  }
}

class GetLatestRadioStatusReply {
  radio_status: ?cwn_radios.Status;
  range_value: number;
  range_type: GetLatestRadioStatusReply_RangeType;
  is_aligned: boolean;

  constructor(props: $Shape<GetLatestRadioStatusReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.radio_status = null;
    if (props.hasOwnProperty('radio_status')) {
      const v = props.radio_status;
      this.radio_status = v && new cwn_radios.Status(v);
    }

    this.range_value = 0;
    if (props.hasOwnProperty('range_value')) {
      const v = props.range_value;
      this.range_value = v;
    }

    this.range_type = GetLatestRadioStatusReply_RangeTypeValue(0);
    if (props.hasOwnProperty('range_type')) {
      const v = props.range_type;
      this.range_type = v;
    }

    this.is_aligned = false;
    if (props.hasOwnProperty('is_aligned')) {
      const v = props.is_aligned;
      this.is_aligned = !!v;
    }
  }

  getRadioStatus(): cwn_radios.Status {
    if (this.radio_status) {
      return this.radio_status;
    }
    return new cwn_radios.Status();
  }
}
export {GetLatestRadioStatusReply};

class SiteRadioLinkDetails_TargetDetails {
  srl: ?cwn_site.SiteRadioLink;
  address: ?cwn_customers.Address;
  range: ?cwn_antenna.Range;

  constructor(props: $Shape<SiteRadioLinkDetails_TargetDetails> = {}): void {
    if (!props) {
      props = {};
    }

    this.srl = null;
    if (props.hasOwnProperty('srl')) {
      const v = props.srl;
      this.srl = v && new cwn_site.SiteRadioLink(v);
    }

    this.address = null;
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v && new cwn_customers.Address(v);
    }

    this.range = null;
    if (props.hasOwnProperty('range')) {
      const v = props.range;
      this.range = v && new cwn_antenna.Range(v);
    }
  }

  getSrl(): cwn_site.SiteRadioLink {
    if (this.srl) {
      return this.srl;
    }
    return new cwn_site.SiteRadioLink();
  }

  getAddress(): cwn_customers.Address {
    if (this.address) {
      return this.address;
    }
    return new cwn_customers.Address();
  }

  getRange(): cwn_antenna.Range {
    if (this.range) {
      return this.range;
    }
    return new cwn_antenna.Range();
  }
}
export {SiteRadioLinkDetails_TargetDetails};
class SiteRadioLinkDetails {
  srl: ?cwn_site.SiteRadioLink;
  targets: Array<SiteRadioLinkDetails_TargetDetails>;

  constructor(props: $Shape<SiteRadioLinkDetails> = {}): void {
    if (!props) {
      props = {};
    }

    this.srl = null;
    if (props.hasOwnProperty('srl')) {
      const v = props.srl;
      this.srl = v && new cwn_site.SiteRadioLink(v);
    }

    this.targets = [];
    if (props.hasOwnProperty('targets')) {
      const v = props.targets;
      if (!Array.isArray(v)) {
        throw new Error('repeated field targets should be array');
      }
      this.targets = v.map(function(v) {
        return new SiteRadioLinkDetails_TargetDetails(v);
      });
    }
  }

  getSrl(): cwn_site.SiteRadioLink {
    if (this.srl) {
      return this.srl;
    }
    return new cwn_site.SiteRadioLink();
  }
}
export {SiteRadioLinkDetails};

class GetSiteBridgeConfigsReply {
  bridge_configs: Array<cwn_site.SiteBridgeConfig>;

  constructor(props: $Shape<GetSiteBridgeConfigsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.bridge_configs = [];
    if (props.hasOwnProperty('bridge_configs')) {
      const v = props.bridge_configs;
      if (!Array.isArray(v)) {
        throw new Error('repeated field bridge_configs should be array');
      }
      this.bridge_configs = v.map(function(v) {
        return new cwn_site.SiteBridgeConfig(v);
      });
    }
  }
}
export {GetSiteBridgeConfigsReply};

class UpdateSiteBridgeConfigsRequest {
  site_id: string;
  bridge_configs: Array<cwn_site.SiteBridgeConfig>;

  constructor(props: $Shape<UpdateSiteBridgeConfigsRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.bridge_configs = [];
    if (props.hasOwnProperty('bridge_configs')) {
      const v = props.bridge_configs;
      if (!Array.isArray(v)) {
        throw new Error('repeated field bridge_configs should be array');
      }
      this.bridge_configs = v.map(function(v) {
        return new cwn_site.SiteBridgeConfig(v);
      });
    }
  }
}
export {UpdateSiteBridgeConfigsRequest};

class UpdateSiteBridgeConfigsReply {
  ids: Array<string>;

  constructor(props: $Shape<UpdateSiteBridgeConfigsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.ids = [];
    if (props.hasOwnProperty('ids')) {
      const v = props.ids;
      if (!Array.isArray(v)) {
        throw new Error('repeated field ids should be array');
      }
      this.ids = v.map(function(v) {
        return v;
      });
    }
  }
}
export {UpdateSiteBridgeConfigsReply};

class TechService {
  baseURL: string;
  additionalHeaders: Array<Array<string>>;
  headersProvider: ?() => Array<Array<string>>;

  constructor(baseURL: string) {
    this.baseURL = baseURL;
    this.additionalHeaders = [];
  }

  async call<Req, Res>(
    method: string,
    req: Req,
    ResClass: Class<Res>,
  ): Promise<Res> {
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    this.additionalHeaders.forEach(function(h) {
      headers.append(h[0], h[1]);
    });

    if (this.headersProvider) {
      this.headersProvider().forEach(h => {
        headers.append(h[0], h[1]);
      });
    }

    const credentials =
      process.env.NODE_ENV === 'production' ? 'same-origin' : 'include';

    let res: Response;
    let resText: string;
    try {
      res = await fetch(
        this.baseURL + '/cwn.tech.server.TechService/' + method,
        {
          credentials,
          method: 'POST',
          headers: headers,
          body: JSON.stringify(req),
        },
      );
      resText = await res.text();
    } catch (e) {
      if (e instanceof TypeError) {
        throw new Error(
          'Could not connect to server (reason: ' +
            e.message +
            ') Check your Internet connection and try again.',
        );
      } else {
        throw e;
      }
    }

    if (res.ok) {
      try {
        const json = JSON.parse(resText);
        // https://flow.org/en/docs/types/utilities/#toc-class
        // $FlowFixMe this will work in a future version of flow
        return new ResClass(json);
      } catch (e) {
        throw new Error(
          'Could not parse response for ' +
            method +
            ': ' +
            e.message +
            ' payload: ' +
            resText,
        );
      }
    }

    let errMessage: string;
    try {
      const errJSON = JSON.parse(resText);
      if (errJSON.code && errJSON.message) {
        // RPCError
        errMessage = errJSON.code + ': ' + errJSON.message;
      } else {
        errMessage = JSON.stringify(errJSON, null, 2);
      }
    } catch (e) {
      errMessage = resText;
    }
    throw new Error(
      'Request for ' +
        method +
        ' failed: ' +
        res.status +
        ' ' +
        res.statusText +
        ' ' +
        errMessage,
    );
  }

  addHeader(name: string, value: string) {
    this.additionalHeaders.push([name, value]);
  }

  async GetUser(req: cwn_rpc.Empty): Promise<cwn_user.User> {
    return await this.call('GetUser', req, cwn_user.User);
  }
  async GetWorkOrder(req: cwn_rpc.Id): Promise<WorkOrderDetails> {
    return await this.call('GetWorkOrder', req, WorkOrderDetails);
  }
  async SearchWorkOrders(
    req: cwn_search.Query,
  ): Promise<SearchWorkOrdersResponse> {
    return await this.call('SearchWorkOrders', req, SearchWorkOrdersResponse);
  }
  async CompleteTask(req: UpdateTaskReq): Promise<cwn_rpc.Empty> {
    return await this.call('CompleteTask', req, cwn_rpc.Empty);
  }
  async UpdateTask(req: UpdateTaskReq): Promise<cwn_rpc.Empty> {
    return await this.call('UpdateTask', req, cwn_rpc.Empty);
  }
  async StartWorkOrder(req: cwn_rpc.Id): Promise<cwn_rpc.Empty> {
    return await this.call('StartWorkOrder', req, cwn_rpc.Empty);
  }
  async CompleteWorkOrder(req: CloseWorkOrderRequest): Promise<cwn_rpc.Empty> {
    return await this.call('CompleteWorkOrder', req, cwn_rpc.Empty);
  }
  async FailWorkOrder(req: CloseWorkOrderRequest): Promise<cwn_rpc.Empty> {
    return await this.call('FailWorkOrder', req, cwn_rpc.Empty);
  }
  async GetShotsForWorkOrder(
    req: cwn_rpc.Id,
  ): Promise<ShotsForWorkOrderResponse> {
    return await this.call(
      'GetShotsForWorkOrder',
      req,
      ShotsForWorkOrderResponse,
    );
  }
  async GetSiteRadioLink(req: cwn_rpc.Id): Promise<SiteRadioLinkDetails> {
    return await this.call('GetSiteRadioLink', req, SiteRadioLinkDetails);
  }
  async UpdateSiteRadioLinks(
    req: UpdateSiteRadioLinksRequest,
  ): Promise<cwn_rpc.Empty> {
    return await this.call('UpdateSiteRadioLinks', req, cwn_rpc.Empty);
  }
  async GetUNMSConfig(req: cwn_rpc.Id): Promise<GetUNMSConfigReply> {
    return await this.call('GetUNMSConfig', req, GetUNMSConfigReply);
  }
  async GetOrCreateEquipment(
    req: cwn_rpc.StringId,
  ): Promise<cwn_site_hw.Equipment> {
    return await this.call('GetOrCreateEquipment', req, cwn_site_hw.Equipment);
  }
  async UpdateEquipmentDevice(
    req: UpdateEquipmentDeviceRequest,
  ): Promise<cwn_rpc.Empty> {
    return await this.call('UpdateEquipmentDevice', req, cwn_rpc.Empty);
  }
  async GetSite(req: cwn_rpc.StringId): Promise<cwn_site.Site> {
    return await this.call('GetSite', req, cwn_site.Site);
  }
  async AssignSiteDevice(req: AssignSiteDeviceRequest): Promise<cwn_rpc.Empty> {
    return await this.call('AssignSiteDevice', req, cwn_rpc.Empty);
  }
  async GetSiteBridgeConfigs(
    req: cwn_rpc.StringId,
  ): Promise<GetSiteBridgeConfigsReply> {
    return await this.call(
      'GetSiteBridgeConfigs',
      req,
      GetSiteBridgeConfigsReply,
    );
  }
  async UpdateSiteBridgeConfigs(
    req: UpdateSiteBridgeConfigsRequest,
  ): Promise<UpdateSiteBridgeConfigsReply> {
    return await this.call(
      'UpdateSiteBridgeConfigs',
      req,
      UpdateSiteBridgeConfigsReply,
    );
  }
  async GetBuildingDescription(
    req: cwn_rpc.StringId,
  ): Promise<cwn_site_details.BuildingDescription> {
    return await this.call(
      'GetBuildingDescription',
      req,
      cwn_site_details.BuildingDescription,
    );
  }
  async UpdateBuildingDescription(
    req: UpdateBuildingDescriptionRequest,
  ): Promise<cwn_rpc.Empty> {
    return await this.call('UpdateBuildingDescription', req, cwn_rpc.Empty);
  }
  async CheckAddApPrerequisitesComplete(
    req: cwn_rpc.Id,
  ): Promise<CheckAddApPrerequisitesCompleteResponse> {
    return await this.call(
      'CheckAddApPrerequisitesComplete',
      req,
      CheckAddApPrerequisitesCompleteResponse,
    );
  }
  async CheckCollectBillingComplete(
    req: cwn_rpc.Id,
  ): Promise<CheckCollectBillingCompleteResponse> {
    return await this.call(
      'CheckCollectBillingComplete',
      req,
      CheckCollectBillingCompleteResponse,
    );
  }
  async GetLead(req: cwn_rpc.Id): Promise<cwn_lead.Lead> {
    return await this.call('GetLead', req, cwn_lead.Lead);
  }
  async ActivateLead(req: ActivateLeadRequest): Promise<cwn_rpc.Empty> {
    return await this.call('ActivateLead', req, cwn_rpc.Empty);
  }
  async GetSignedURL(req: GetSignedURLReq): Promise<GetSignedURLRes> {
    return await this.call('GetSignedURL', req, GetSignedURLRes);
  }
  async UpdatePhotos(req: Photos): Promise<Photos> {
    return await this.call('UpdatePhotos', req, Photos);
  }
  async DeletePhoto(req: cwn_rpc.StringId): Promise<cwn_rpc.Empty> {
    return await this.call('DeletePhoto', req, cwn_rpc.Empty);
  }
  async GetEmailForLead(req: cwn_rpc.Id): Promise<cwn_rpc.Email> {
    return await this.call('GetEmailForLead', req, cwn_rpc.Email);
  }
  async SendBillingInfoRequestEmail(
    req: SendBillingInfoRequestEmailRequest,
  ): Promise<cwn_rpc.Empty> {
    return await this.call('SendBillingInfoRequestEmail', req, cwn_rpc.Empty);
  }
  async FindAllQBPsWithinBounds(
    req: FindAllQBPsWithinBoundsRequest,
  ): Promise<FindAllQBPsWithinBoundsResponse> {
    return await this.call(
      'FindAllQBPsWithinBounds',
      req,
      FindAllQBPsWithinBoundsResponse,
    );
  }
  async GetMount(req: cwn_rpc.StringId): Promise<GetMountResponse> {
    return await this.call('GetMount', req, GetMountResponse);
  }
  async UpdateMount(req: UpdateMountRequest): Promise<cwn_rpc.Empty> {
    return await this.call('UpdateMount', req, cwn_rpc.Empty);
  }
  async SearchDevices(req: cwn_search.Query): Promise<SearchDevicesResponse> {
    return await this.call('SearchDevices', req, SearchDevicesResponse);
  }
  async GetHanForSubscription(
    req: cwn_rpc.Id,
  ): Promise<GetHanForSubscriptionResponse> {
    return await this.call(
      'GetHanForSubscription',
      req,
      GetHanForSubscriptionResponse,
    );
  }
  async CreateHan(req: CreateHanRequest): Promise<cwn_han.Han> {
    return await this.call('CreateHan', req, cwn_han.Han);
  }
  async UpdateHan(req: UpdateHanRequest): Promise<UpdateHanResponse> {
    return await this.call('UpdateHan', req, UpdateHanResponse);
  }
  async UpdateDeclineAp(req: UpdateDeclineApRequest): Promise<cwn_rpc.Empty> {
    return await this.call('UpdateDeclineAp', req, cwn_rpc.Empty);
  }
  async GetLastUnifiStatuses(
    req: GetLastUnifiStatusesRequest,
  ): Promise<GetLastUnifiStatusesResponse> {
    return await this.call(
      'GetLastUnifiStatuses',
      req,
      GetLastUnifiStatusesResponse,
    );
  }
  async GetLatestRadioStatus(
    req: cwn_rpc.StringId,
  ): Promise<GetLatestRadioStatusReply> {
    return await this.call(
      'GetLatestRadioStatus',
      req,
      GetLatestRadioStatusReply,
    );
  }
  async GetRadio(req: cwn_rpc.StringId): Promise<cwn_radios.Radio> {
    return await this.call('GetRadio', req, cwn_radios.Radio);
  }
  async GetRadioGroup(req: cwn_rpc.Id): Promise<cwn_radios.RadioGroup> {
    return await this.call('GetRadioGroup', req, cwn_radios.RadioGroup);
  }
  async HealthCheck(req: cwn_rpc.StringId): Promise<HealthCheckReply> {
    return await this.call('HealthCheck', req, HealthCheckReply);
  }
  async RunEeroSpeedTest(req: cwn_rpc.Id): Promise<cwn_rpc.Empty> {
    return await this.call('RunEeroSpeedTest', req, cwn_rpc.Empty);
  }
  async GetLatestEeroSpeedtest(
    req: cwn_rpc.Id,
  ): Promise<cwn_eero.NetworkSpeedTest> {
    return await this.call(
      'GetLatestEeroSpeedtest',
      req,
      cwn_eero.NetworkSpeedTest,
    );
  }
}
export {TechService};

// END cwn/tech/server/service.proto
