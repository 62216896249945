// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */
import * as cwn_ubnt_unifi from '../../cwn/ubnt/unifi';
import * as cwn_han from '../../cwn/han';

// START cwn/eero/models.proto

class ResponseMeta {
  code: number;
  error: string;
  server_time: ?Date;

  constructor(props: $Shape<ResponseMeta> = {}): void {
    if (!props) {
      props = {};
    }

    this.code = 0;
    if (props.hasOwnProperty('code')) {
      const v = props.code;
      this.code = v;
    }

    this.error = '';
    if (props.hasOwnProperty('error')) {
      const v = props.error;
      this.error = v;
    }

    this.server_time = null;
    if (props.hasOwnProperty('server_time')) {
      const v = props.server_time;
      this.server_time = v && new Date(v);
    }
  }
}
export {ResponseMeta};

class ResponsePagination {
  next: string;

  constructor(props: $Shape<ResponsePagination> = {}): void {
    if (!props) {
      props = {};
    }

    this.next = '';
    if (props.hasOwnProperty('next')) {
      const v = props.next;
      this.next = v;
    }
  }
}
export {ResponsePagination};

class DatalessResponse {
  meta: ?ResponseMeta;

  constructor(props: $Shape<DatalessResponse> = {}): void {
    if (!props) {
      props = {};
    }

    this.meta = null;
    if (props.hasOwnProperty('meta')) {
      const v = props.meta;
      this.meta = v && new ResponseMeta(v);
    }
  }

  getMeta(): ResponseMeta {
    if (this.meta) {
      return this.meta;
    }
    return new ResponseMeta();
  }
}
export {DatalessResponse};

class SummaryNetworksResponse {
  meta: ?ResponseMeta;
  data: ?SummaryNetworks;
  pagination: ?ResponsePagination;

  constructor(props: $Shape<SummaryNetworksResponse> = {}): void {
    if (!props) {
      props = {};
    }

    this.meta = null;
    if (props.hasOwnProperty('meta')) {
      const v = props.meta;
      this.meta = v && new ResponseMeta(v);
    }

    this.data = null;
    if (props.hasOwnProperty('data')) {
      const v = props.data;
      this.data = v && new SummaryNetworks(v);
    }

    this.pagination = null;
    if (props.hasOwnProperty('pagination')) {
      const v = props.pagination;
      this.pagination = v && new ResponsePagination(v);
    }
  }

  getMeta(): ResponseMeta {
    if (this.meta) {
      return this.meta;
    }
    return new ResponseMeta();
  }

  getData(): SummaryNetworks {
    if (this.data) {
      return this.data;
    }
    return new SummaryNetworks();
  }

  getPagination(): ResponsePagination {
    if (this.pagination) {
      return this.pagination;
    }
    return new ResponsePagination();
  }
}
export {SummaryNetworksResponse};

class SummaryNetworks {
  url: string;
  networks: Array<SummaryNetwork>;

  constructor(props: $Shape<SummaryNetworks> = {}): void {
    if (!props) {
      props = {};
    }

    this.url = '';
    if (props.hasOwnProperty('url')) {
      const v = props.url;
      this.url = v;
    }

    this.networks = [];
    if (props.hasOwnProperty('networks')) {
      const v = props.networks;
      if (!Array.isArray(v)) {
        throw new Error('repeated field networks should be array');
      }
      this.networks = v.map(function(v) {
        return new SummaryNetwork(v);
      });
    }
  }
}
export {SummaryNetworks};

export const NetworkHealth_StatusEnumValues = {
  UNKNOWN: 0,
  CONNECTED: 1,
  IN_PROGRESS: 2,
  ERROR: 3,
  REBOOTING: 4,
};
type NetworkHealth_StatusEnum = $Keys<typeof NetworkHealth_StatusEnumValues>;
export type {NetworkHealth_StatusEnum};

export function NetworkHealth_StatusEnumValue(
  n: number,
): NetworkHealth_StatusEnum {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'CONNECTED';

    case 2:
      return 'IN_PROGRESS';

    case 3:
      return 'ERROR';

    case 4:
      return 'REBOOTING';

    default:
      return 'UNKNOWN';
  }
}

class NetworkHealth_Status {
  status: NetworkHealth_StatusEnum;

  constructor(props: $Shape<NetworkHealth_Status> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = NetworkHealth_StatusEnumValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }
  }
}
export {NetworkHealth_Status};
class NetworkHealth {
  internet: ?NetworkHealth_Status;
  eero_network: ?NetworkHealth_Status;

  constructor(props: $Shape<NetworkHealth> = {}): void {
    if (!props) {
      props = {};
    }

    this.internet = null;
    if (props.hasOwnProperty('internet')) {
      const v = props.internet;
      this.internet = v && new NetworkHealth_Status(v);
    }

    this.eero_network = null;
    if (props.hasOwnProperty('eero_network')) {
      const v = props.eero_network;
      this.eero_network = v && new NetworkHealth_Status(v);
    }
  }

  getInternet(): NetworkHealth_Status {
    if (this.internet) {
      return this.internet;
    }
    return new NetworkHealth_Status();
  }

  getEeroNetwork(): NetworkHealth_Status {
    if (this.eero_network) {
      return this.eero_network;
    }
    return new NetworkHealth_Status();
  }
}
export {NetworkHealth};

class SummaryNetwork_Eero {
  mac_address: string;
  serial: string;

  constructor(props: $Shape<SummaryNetwork_Eero> = {}): void {
    if (!props) {
      props = {};
    }

    this.mac_address = '';
    if (props.hasOwnProperty('mac_address')) {
      const v = props.mac_address;
      this.mac_address = v;
    }

    this.serial = '';
    if (props.hasOwnProperty('serial')) {
      const v = props.serial;
      this.serial = v;
    }
  }
}
export {SummaryNetwork_Eero};
class SummaryNetwork {
  url: string;
  name: string;
  owner: string;
  health: ?NetworkHealth;
  eeros: Array<SummaryNetwork_Eero>;

  constructor(props: $Shape<SummaryNetwork> = {}): void {
    if (!props) {
      props = {};
    }

    this.url = '';
    if (props.hasOwnProperty('url')) {
      const v = props.url;
      this.url = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.owner = '';
    if (props.hasOwnProperty('owner')) {
      const v = props.owner;
      this.owner = v;
    }

    this.health = null;
    if (props.hasOwnProperty('health')) {
      const v = props.health;
      this.health = v && new NetworkHealth(v);
    }

    this.eeros = [];
    if (props.hasOwnProperty('eeros')) {
      const v = props.eeros;
      if (!Array.isArray(v)) {
        throw new Error('repeated field eeros should be array');
      }
      this.eeros = v.map(function(v) {
        return new SummaryNetwork_Eero(v);
      });
    }
  }

  getHealth(): NetworkHealth {
    if (this.health) {
      return this.health;
    }
    return new NetworkHealth();
  }
}
export {SummaryNetwork};

class FullNetworkResponse {
  meta: ?ResponseMeta;
  data: ?FullNetwork;

  constructor(props: $Shape<FullNetworkResponse> = {}): void {
    if (!props) {
      props = {};
    }

    this.meta = null;
    if (props.hasOwnProperty('meta')) {
      const v = props.meta;
      this.meta = v && new ResponseMeta(v);
    }

    this.data = null;
    if (props.hasOwnProperty('data')) {
      const v = props.data;
      this.data = v && new FullNetwork(v);
    }
  }

  getMeta(): ResponseMeta {
    if (this.meta) {
      return this.meta;
    }
    return new ResponseMeta();
  }

  getData(): FullNetwork {
    if (this.data) {
      return this.data;
    }
    return new FullNetwork();
  }
}
export {FullNetworkResponse};

export const FullNetwork_Connection_ModeValues = {
  UNKNOWN: 0,
  NAT: 1,
  BRIDGED: 2,
};
type FullNetwork_Connection_Mode = $Keys<
  typeof FullNetwork_Connection_ModeValues,
>;
export type {FullNetwork_Connection_Mode};

export function FullNetwork_Connection_ModeValue(
  n: number,
): FullNetwork_Connection_Mode {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'NAT';

    case 2:
      return 'BRIDGED';

    default:
      return 'UNKNOWN';
  }
}

class FullNetwork_Connection {
  mode: FullNetwork_Connection_Mode;

  constructor(props: $Shape<FullNetwork_Connection> = {}): void {
    if (!props) {
      props = {};
    }

    this.mode = FullNetwork_Connection_ModeValue(0);
    if (props.hasOwnProperty('mode')) {
      const v = props.mode;
      this.mode = v;
    }
  }
}
export {FullNetwork_Connection};
export const FullNetwork_Lease_ModeValues = {
  UNKNOWN: 0,
  DHCP: 1,
  STATIC: 2,
};
type FullNetwork_Lease_Mode = $Keys<typeof FullNetwork_Lease_ModeValues>;
export type {FullNetwork_Lease_Mode};

export function FullNetwork_Lease_ModeValue(n: number): FullNetwork_Lease_Mode {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'DHCP';

    case 2:
      return 'STATIC';

    default:
      return 'UNKNOWN';
  }
}

class FullNetwork_Lease_Ip {
  ip: string;
  mask: string;
  router: string;

  constructor(props: $Shape<FullNetwork_Lease_Ip> = {}): void {
    if (!props) {
      props = {};
    }

    this.ip = '';
    if (props.hasOwnProperty('ip')) {
      const v = props.ip;
      this.ip = v;
    }

    this.mask = '';
    if (props.hasOwnProperty('mask')) {
      const v = props.mask;
      this.mask = v;
    }

    this.router = '';
    if (props.hasOwnProperty('router')) {
      const v = props.router;
      this.router = v;
    }
  }
}
export {FullNetwork_Lease_Ip};
class FullNetwork_Lease {
  mode: FullNetwork_Lease_Mode;
  dhcp: ?FullNetwork_Lease_Ip;
  Static: ?FullNetwork_Lease_Ip;

  constructor(props: $Shape<FullNetwork_Lease> = {}): void {
    if (!props) {
      props = {};
    }

    this.mode = FullNetwork_Lease_ModeValue(0);
    if (props.hasOwnProperty('mode')) {
      const v = props.mode;
      this.mode = v;
    }

    this.dhcp = null;
    if (props.hasOwnProperty('dhcp')) {
      const v = props.dhcp;
      this.dhcp = v && new FullNetwork_Lease_Ip(v);
    }

    this.Static = null;
    if (props.hasOwnProperty('Static')) {
      const v = props.Static;
      this.Static = v && new FullNetwork_Lease_Ip(v);
    }
  }

  getDhcp(): FullNetwork_Lease_Ip {
    if (this.dhcp) {
      return this.dhcp;
    }
    return new FullNetwork_Lease_Ip();
  }

  getStatic(): FullNetwork_Lease_Ip {
    if (this.Static) {
      return this.Static;
    }
    return new FullNetwork_Lease_Ip();
  }
}
export {FullNetwork_Lease};
export const FullNetwork_Dhcp_ModeValues = {
  UNKNOWN: 0,
  AUTOMATIC: 1,
  CUSTOM: 2,
};
type FullNetwork_Dhcp_Mode = $Keys<typeof FullNetwork_Dhcp_ModeValues>;
export type {FullNetwork_Dhcp_Mode};

export function FullNetwork_Dhcp_ModeValue(n: number): FullNetwork_Dhcp_Mode {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'AUTOMATIC';

    case 2:
      return 'CUSTOM';

    default:
      return 'UNKNOWN';
  }
}

class FullNetwork_Dhcp_Custom {
  subnet_ip: string;
  subnet_mask: string;
  start_ip: string;
  end_ip: string;

  constructor(props: $Shape<FullNetwork_Dhcp_Custom> = {}): void {
    if (!props) {
      props = {};
    }

    this.subnet_ip = '';
    if (props.hasOwnProperty('subnet_ip')) {
      const v = props.subnet_ip;
      this.subnet_ip = v;
    }

    this.subnet_mask = '';
    if (props.hasOwnProperty('subnet_mask')) {
      const v = props.subnet_mask;
      this.subnet_mask = v;
    }

    this.start_ip = '';
    if (props.hasOwnProperty('start_ip')) {
      const v = props.start_ip;
      this.start_ip = v;
    }

    this.end_ip = '';
    if (props.hasOwnProperty('end_ip')) {
      const v = props.end_ip;
      this.end_ip = v;
    }
  }
}
export {FullNetwork_Dhcp_Custom};
class FullNetwork_Dhcp {
  mode: FullNetwork_Dhcp_Mode;
  custom: ?FullNetwork_Dhcp_Custom;

  constructor(props: $Shape<FullNetwork_Dhcp> = {}): void {
    if (!props) {
      props = {};
    }

    this.mode = FullNetwork_Dhcp_ModeValue(0);
    if (props.hasOwnProperty('mode')) {
      const v = props.mode;
      this.mode = v;
    }

    this.custom = null;
    if (props.hasOwnProperty('custom')) {
      const v = props.custom;
      this.custom = v && new FullNetwork_Dhcp_Custom(v);
    }
  }

  getCustom(): FullNetwork_Dhcp_Custom {
    if (this.custom) {
      return this.custom;
    }
    return new FullNetwork_Dhcp_Custom();
  }
}
export {FullNetwork_Dhcp};
export const FullNetwork_Dns_ModeValues = {
  UNKNOWN: 0,
  AUTOMATIC: 1,
  CUSTOM: 2,
};
type FullNetwork_Dns_Mode = $Keys<typeof FullNetwork_Dns_ModeValues>;
export type {FullNetwork_Dns_Mode};

export function FullNetwork_Dns_ModeValue(n: number): FullNetwork_Dns_Mode {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'AUTOMATIC';

    case 2:
      return 'CUSTOM';

    default:
      return 'UNKNOWN';
  }
}

class FullNetwork_Dns_Ips {
  ips: Array<string>;

  constructor(props: $Shape<FullNetwork_Dns_Ips> = {}): void {
    if (!props) {
      props = {};
    }

    this.ips = [];
    if (props.hasOwnProperty('ips')) {
      const v = props.ips;
      if (!Array.isArray(v)) {
        throw new Error('repeated field ips should be array');
      }
      this.ips = v.map(function(v) {
        return v;
      });
    }
  }
}
export {FullNetwork_Dns_Ips};
class FullNetwork_Dns {
  mode: FullNetwork_Dns_Mode;
  parent: ?FullNetwork_Dns_Ips;
  custom: ?FullNetwork_Dns_Ips;

  constructor(props: $Shape<FullNetwork_Dns> = {}): void {
    if (!props) {
      props = {};
    }

    this.mode = FullNetwork_Dns_ModeValue(0);
    if (props.hasOwnProperty('mode')) {
      const v = props.mode;
      this.mode = v;
    }

    this.parent = null;
    if (props.hasOwnProperty('parent')) {
      const v = props.parent;
      this.parent = v && new FullNetwork_Dns_Ips(v);
    }

    this.custom = null;
    if (props.hasOwnProperty('custom')) {
      const v = props.custom;
      this.custom = v && new FullNetwork_Dns_Ips(v);
    }
  }

  getParent(): FullNetwork_Dns_Ips {
    if (this.parent) {
      return this.parent;
    }
    return new FullNetwork_Dns_Ips();
  }

  getCustom(): FullNetwork_Dns_Ips {
    if (this.custom) {
      return this.custom;
    }
    return new FullNetwork_Dns_Ips();
  }
}
export {FullNetwork_Dns};
export const FullNetwork_Speed_StatusValues = {
  NONE: 0,
  PENDING: 1,
  RUNNING: 2,
};
type FullNetwork_Speed_Status = $Keys<typeof FullNetwork_Speed_StatusValues>;
export type {FullNetwork_Speed_Status};

export function FullNetwork_Speed_StatusValue(
  n: number,
): FullNetwork_Speed_Status {
  switch (n) {
    case 0:
      return 'NONE';

    case 1:
      return 'PENDING';

    case 2:
      return 'RUNNING';

    default:
      return 'NONE';
  }
}

class FullNetwork_Speed_Measurement {
  units: string;
  value: number;

  constructor(props: $Shape<FullNetwork_Speed_Measurement> = {}): void {
    if (!props) {
      props = {};
    }

    this.units = '';
    if (props.hasOwnProperty('units')) {
      const v = props.units;
      this.units = v;
    }

    this.value = 0;
    if (props.hasOwnProperty('value')) {
      const v = props.value;
      this.value = v;
    }
  }
}
export {FullNetwork_Speed_Measurement};
class FullNetwork_Speed {
  status: FullNetwork_Speed_Status;
  date: ?Date;
  up: ?FullNetwork_Speed_Measurement;
  down: ?FullNetwork_Speed_Measurement;

  constructor(props: $Shape<FullNetwork_Speed> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = FullNetwork_Speed_StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.date = null;
    if (props.hasOwnProperty('date')) {
      const v = props.date;
      this.date = v && new Date(v);
    }

    this.up = null;
    if (props.hasOwnProperty('up')) {
      const v = props.up;
      this.up = v && new FullNetwork_Speed_Measurement(v);
    }

    this.down = null;
    if (props.hasOwnProperty('down')) {
      const v = props.down;
      this.down = v && new FullNetwork_Speed_Measurement(v);
    }
  }

  getUp(): FullNetwork_Speed_Measurement {
    if (this.up) {
      return this.up;
    }
    return new FullNetwork_Speed_Measurement();
  }

  getDown(): FullNetwork_Speed_Measurement {
    if (this.down) {
      return this.down;
    }
    return new FullNetwork_Speed_Measurement();
  }
}
export {FullNetwork_Speed};
class FullNetwork_Timezone {
  value: string;
  geo_up: string;

  constructor(props: $Shape<FullNetwork_Timezone> = {}): void {
    if (!props) {
      props = {};
    }

    this.value = '';
    if (props.hasOwnProperty('value')) {
      const v = props.value;
      this.value = v;
    }

    this.geo_up = '';
    if (props.hasOwnProperty('geo_up')) {
      const v = props.geo_up;
      this.geo_up = v;
    }
  }
}
export {FullNetwork_Timezone};
class FullNetwork_Upstream {
  constructor(props: $Shape<FullNetwork_Upstream> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {FullNetwork_Upstream};
class FullNetwork_IpSettings {
  double_nat: boolean;
  public_ip: string;

  constructor(props: $Shape<FullNetwork_IpSettings> = {}): void {
    if (!props) {
      props = {};
    }

    this.double_nat = false;
    if (props.hasOwnProperty('double_nat')) {
      const v = props.double_nat;
      this.double_nat = !!v;
    }

    this.public_ip = '';
    if (props.hasOwnProperty('public_ip')) {
      const v = props.public_ip;
      this.public_ip = v;
    }
  }
}
export {FullNetwork_IpSettings};
class FullNetwork_Rebooting {
  constructor(props: $Shape<FullNetwork_Rebooting> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {FullNetwork_Rebooting};
class FullNetwork_User {
  email: string;

  constructor(props: $Shape<FullNetwork_User> = {}): void {
    if (!props) {
      props = {};
    }

    this.email = '';
    if (props.hasOwnProperty('email')) {
      const v = props.email;
      this.email = v;
    }
  }
}
export {FullNetwork_User};
class FullNetwork {
  url: string;
  name: string;
  password: string;
  wan_ip: string;
  gateway_ip: string;
  connection: ?FullNetwork_Connection;
  hairpin_nat: boolean;
  lease: ?FullNetwork_Lease;
  dhcp: ?FullNetwork_Dhcp;
  dns: ?FullNetwork_Dns;
  upnp: boolean;
  speed: ?FullNetwork_Speed;
  timezone: ?FullNetwork_Timezone;
  health: ?NetworkHealth;
  upstream: Array<FullNetwork_Upstream>;
  ip_settings: ?FullNetwork_IpSettings;
  owner: string;
  rebooting: ?FullNetwork_Rebooting;
  last_reboot: ?Date;
  installer: ?FullNetwork_User;
  transferer: string;

  constructor(props: $Shape<FullNetwork> = {}): void {
    if (!props) {
      props = {};
    }

    this.url = '';
    if (props.hasOwnProperty('url')) {
      const v = props.url;
      this.url = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.password = '';
    if (props.hasOwnProperty('password')) {
      const v = props.password;
      this.password = v;
    }

    this.wan_ip = '';
    if (props.hasOwnProperty('wan_ip')) {
      const v = props.wan_ip;
      this.wan_ip = v;
    }

    this.gateway_ip = '';
    if (props.hasOwnProperty('gateway_ip')) {
      const v = props.gateway_ip;
      this.gateway_ip = v;
    }

    this.connection = null;
    if (props.hasOwnProperty('connection')) {
      const v = props.connection;
      this.connection = v && new FullNetwork_Connection(v);
    }

    this.hairpin_nat = false;
    if (props.hasOwnProperty('hairpin_nat')) {
      const v = props.hairpin_nat;
      this.hairpin_nat = !!v;
    }

    this.lease = null;
    if (props.hasOwnProperty('lease')) {
      const v = props.lease;
      this.lease = v && new FullNetwork_Lease(v);
    }

    this.dhcp = null;
    if (props.hasOwnProperty('dhcp')) {
      const v = props.dhcp;
      this.dhcp = v && new FullNetwork_Dhcp(v);
    }

    this.dns = null;
    if (props.hasOwnProperty('dns')) {
      const v = props.dns;
      this.dns = v && new FullNetwork_Dns(v);
    }

    this.upnp = false;
    if (props.hasOwnProperty('upnp')) {
      const v = props.upnp;
      this.upnp = !!v;
    }

    this.speed = null;
    if (props.hasOwnProperty('speed')) {
      const v = props.speed;
      this.speed = v && new FullNetwork_Speed(v);
    }

    this.timezone = null;
    if (props.hasOwnProperty('timezone')) {
      const v = props.timezone;
      this.timezone = v && new FullNetwork_Timezone(v);
    }

    this.health = null;
    if (props.hasOwnProperty('health')) {
      const v = props.health;
      this.health = v && new NetworkHealth(v);
    }

    this.upstream = [];
    if (props.hasOwnProperty('upstream')) {
      const v = props.upstream;
      if (!Array.isArray(v)) {
        throw new Error('repeated field upstream should be array');
      }
      this.upstream = v.map(function(v) {
        return new FullNetwork_Upstream(v);
      });
    }

    this.ip_settings = null;
    if (props.hasOwnProperty('ip_settings')) {
      const v = props.ip_settings;
      this.ip_settings = v && new FullNetwork_IpSettings(v);
    }

    this.owner = '';
    if (props.hasOwnProperty('owner')) {
      const v = props.owner;
      this.owner = v;
    }

    this.rebooting = null;
    if (props.hasOwnProperty('rebooting')) {
      const v = props.rebooting;
      this.rebooting = v && new FullNetwork_Rebooting(v);
    }

    this.last_reboot = null;
    if (props.hasOwnProperty('last_reboot')) {
      const v = props.last_reboot;
      this.last_reboot = v && new Date(v);
    }

    this.installer = null;
    if (props.hasOwnProperty('installer')) {
      const v = props.installer;
      this.installer = v && new FullNetwork_User(v);
    }

    this.transferer = '';
    if (props.hasOwnProperty('transferer')) {
      const v = props.transferer;
      this.transferer = v;
    }
  }

  getConnection(): FullNetwork_Connection {
    if (this.connection) {
      return this.connection;
    }
    return new FullNetwork_Connection();
  }

  getLease(): FullNetwork_Lease {
    if (this.lease) {
      return this.lease;
    }
    return new FullNetwork_Lease();
  }

  getDhcp(): FullNetwork_Dhcp {
    if (this.dhcp) {
      return this.dhcp;
    }
    return new FullNetwork_Dhcp();
  }

  getDns(): FullNetwork_Dns {
    if (this.dns) {
      return this.dns;
    }
    return new FullNetwork_Dns();
  }

  getSpeed(): FullNetwork_Speed {
    if (this.speed) {
      return this.speed;
    }
    return new FullNetwork_Speed();
  }

  getTimezone(): FullNetwork_Timezone {
    if (this.timezone) {
      return this.timezone;
    }
    return new FullNetwork_Timezone();
  }

  getHealth(): NetworkHealth {
    if (this.health) {
      return this.health;
    }
    return new NetworkHealth();
  }

  getIpSettings(): FullNetwork_IpSettings {
    if (this.ip_settings) {
      return this.ip_settings;
    }
    return new FullNetwork_IpSettings();
  }

  getRebooting(): FullNetwork_Rebooting {
    if (this.rebooting) {
      return this.rebooting;
    }
    return new FullNetwork_Rebooting();
  }

  getInstaller(): FullNetwork_User {
    if (this.installer) {
      return this.installer;
    }
    return new FullNetwork_User();
  }
}
export {FullNetwork};

export const NetworkSettings_Ipv6_NameServers_ModeValues = {
  UNKNOWN: 0,
  AUTOMATIC: 1,
  CUSTOM: 2,
};
type NetworkSettings_Ipv6_NameServers_Mode = $Keys<
  typeof NetworkSettings_Ipv6_NameServers_ModeValues,
>;
export type {NetworkSettings_Ipv6_NameServers_Mode};

export function NetworkSettings_Ipv6_NameServers_ModeValue(
  n: number,
): NetworkSettings_Ipv6_NameServers_Mode {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'AUTOMATIC';

    case 2:
      return 'CUSTOM';

    default:
      return 'UNKNOWN';
  }
}

class NetworkSettings_Ipv6_NameServers {
  mode: NetworkSettings_Ipv6_NameServers_Mode;
  custom: Array<string>;

  constructor(props: $Shape<NetworkSettings_Ipv6_NameServers> = {}): void {
    if (!props) {
      props = {};
    }

    this.mode = NetworkSettings_Ipv6_NameServers_ModeValue(0);
    if (props.hasOwnProperty('mode')) {
      const v = props.mode;
      this.mode = v;
    }

    this.custom = [];
    if (props.hasOwnProperty('custom')) {
      const v = props.custom;
      if (!Array.isArray(v)) {
        throw new Error('repeated field custom should be array');
      }
      this.custom = v.map(function(v) {
        return v;
      });
    }
  }
}
export {NetworkSettings_Ipv6_NameServers};
class NetworkSettings_Ipv6 {
  name_servers: ?NetworkSettings_Ipv6_NameServers;

  constructor(props: $Shape<NetworkSettings_Ipv6> = {}): void {
    if (!props) {
      props = {};
    }

    this.name_servers = null;
    if (props.hasOwnProperty('name_servers')) {
      const v = props.name_servers;
      this.name_servers = v && new NetworkSettings_Ipv6_NameServers(v);
    }
  }

  getNameServers(): NetworkSettings_Ipv6_NameServers {
    if (this.name_servers) {
      return this.name_servers;
    }
    return new NetworkSettings_Ipv6_NameServers();
  }
}
export {NetworkSettings_Ipv6};
class NetworkSettings {
  connection: ?FullNetwork_Connection;
  lease: ?FullNetwork_Lease;
  dns: ?FullNetwork_Dns;
  dhcp: ?FullNetwork_Dhcp;
  upnp: boolean;
  hairpin_nat: boolean;
  sqm: boolean;
  band_steering: boolean;
  thread: boolean;
  ipv6_upstream: boolean;

  constructor(props: $Shape<NetworkSettings> = {}): void {
    if (!props) {
      props = {};
    }

    this.connection = null;
    if (props.hasOwnProperty('connection')) {
      const v = props.connection;
      this.connection = v && new FullNetwork_Connection(v);
    }

    this.lease = null;
    if (props.hasOwnProperty('lease')) {
      const v = props.lease;
      this.lease = v && new FullNetwork_Lease(v);
    }

    this.dns = null;
    if (props.hasOwnProperty('dns')) {
      const v = props.dns;
      this.dns = v && new FullNetwork_Dns(v);
    }

    this.dhcp = null;
    if (props.hasOwnProperty('dhcp')) {
      const v = props.dhcp;
      this.dhcp = v && new FullNetwork_Dhcp(v);
    }

    this.upnp = false;
    if (props.hasOwnProperty('upnp')) {
      const v = props.upnp;
      this.upnp = !!v;
    }

    this.hairpin_nat = false;
    if (props.hasOwnProperty('hairpin_nat')) {
      const v = props.hairpin_nat;
      this.hairpin_nat = !!v;
    }

    this.sqm = false;
    if (props.hasOwnProperty('sqm')) {
      const v = props.sqm;
      this.sqm = !!v;
    }

    this.band_steering = false;
    if (props.hasOwnProperty('band_steering')) {
      const v = props.band_steering;
      this.band_steering = !!v;
    }

    this.thread = false;
    if (props.hasOwnProperty('thread')) {
      const v = props.thread;
      this.thread = !!v;
    }

    this.ipv6_upstream = false;
    if (props.hasOwnProperty('ipv6_upstream')) {
      const v = props.ipv6_upstream;
      this.ipv6_upstream = !!v;
    }
  }

  getConnection(): FullNetwork_Connection {
    if (this.connection) {
      return this.connection;
    }
    return new FullNetwork_Connection();
  }

  getLease(): FullNetwork_Lease {
    if (this.lease) {
      return this.lease;
    }
    return new FullNetwork_Lease();
  }

  getDns(): FullNetwork_Dns {
    if (this.dns) {
      return this.dns;
    }
    return new FullNetwork_Dns();
  }

  getDhcp(): FullNetwork_Dhcp {
    if (this.dhcp) {
      return this.dhcp;
    }
    return new FullNetwork_Dhcp();
  }
}
export {NetworkSettings};

class NetworkEerosResponse {
  meta: ?ResponseMeta;
  data: Array<Eero>;

  constructor(props: $Shape<NetworkEerosResponse> = {}): void {
    if (!props) {
      props = {};
    }

    this.meta = null;
    if (props.hasOwnProperty('meta')) {
      const v = props.meta;
      this.meta = v && new ResponseMeta(v);
    }

    this.data = [];
    if (props.hasOwnProperty('data')) {
      const v = props.data;
      if (!Array.isArray(v)) {
        throw new Error('repeated field data should be array');
      }
      this.data = v.map(function(v) {
        return new Eero(v);
      });
    }
  }

  getMeta(): ResponseMeta {
    if (this.meta) {
      return this.meta;
    }
    return new ResponseMeta();
  }
}
export {NetworkEerosResponse};

export const Eero_StatusValues = {
  UNKNOWN_STATUS: 0,
  RED: 1,
  YELLOW: 2,
  GREEN: 3,
};
type Eero_Status = $Keys<typeof Eero_StatusValues>;
export type {Eero_Status};

export function Eero_StatusValue(n: number): Eero_Status {
  switch (n) {
    case 0:
      return 'UNKNOWN_STATUS';

    case 1:
      return 'RED';

    case 2:
      return 'YELLOW';

    case 3:
      return 'GREEN';

    default:
      return 'UNKNOWN_STATUS';
  }
}

export const Eero_ModelValues = {
  UNKNOWN_MODEL: 0,
  EERO: 1,
  EERO_BEACON: 2,
  EERO_PRO: 3,
  EERO_GEN1: 4,
};
type Eero_Model = $Keys<typeof Eero_ModelValues>;
export type {Eero_Model};

export function Eero_ModelValue(n: number): Eero_Model {
  switch (n) {
    case 0:
      return 'UNKNOWN_MODEL';

    case 1:
      return 'EERO';

    case 2:
      return 'EERO_BEACON';

    case 3:
      return 'EERO_PRO';

    case 4:
      return 'EERO_GEN1';

    default:
      return 'UNKNOWN_MODEL';
  }
}

class Eero {
  url: string;
  serial: string;
  location: string;
  joined: ?Date;
  gateway: boolean;
  ip_address: string;
  status: Eero_Status;
  model: Eero_Model;
  model_number: string;
  ethernet_addresses: Array<string>;
  wifi_bssids: Array<string>;
  os: string;
  mesh_quality_bars: number;
  wired: boolean;
  last_reboot: ?Date;

  constructor(props: $Shape<Eero> = {}): void {
    if (!props) {
      props = {};
    }

    this.url = '';
    if (props.hasOwnProperty('url')) {
      const v = props.url;
      this.url = v;
    }

    this.serial = '';
    if (props.hasOwnProperty('serial')) {
      const v = props.serial;
      this.serial = v;
    }

    this.location = '';
    if (props.hasOwnProperty('location')) {
      const v = props.location;
      this.location = v;
    }

    this.joined = null;
    if (props.hasOwnProperty('joined')) {
      const v = props.joined;
      this.joined = v && new Date(v);
    }

    this.gateway = false;
    if (props.hasOwnProperty('gateway')) {
      const v = props.gateway;
      this.gateway = !!v;
    }

    this.ip_address = '';
    if (props.hasOwnProperty('ip_address')) {
      const v = props.ip_address;
      this.ip_address = v;
    }

    this.status = Eero_StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.model = Eero_ModelValue(0);
    if (props.hasOwnProperty('model')) {
      const v = props.model;
      this.model = v;
    }

    this.model_number = '';
    if (props.hasOwnProperty('model_number')) {
      const v = props.model_number;
      this.model_number = v;
    }

    this.ethernet_addresses = [];
    if (props.hasOwnProperty('ethernet_addresses')) {
      const v = props.ethernet_addresses;
      if (!Array.isArray(v)) {
        throw new Error('repeated field ethernet_addresses should be array');
      }
      this.ethernet_addresses = v.map(function(v) {
        return v;
      });
    }

    this.wifi_bssids = [];
    if (props.hasOwnProperty('wifi_bssids')) {
      const v = props.wifi_bssids;
      if (!Array.isArray(v)) {
        throw new Error('repeated field wifi_bssids should be array');
      }
      this.wifi_bssids = v.map(function(v) {
        return v;
      });
    }

    this.os = '';
    if (props.hasOwnProperty('os')) {
      const v = props.os;
      this.os = v;
    }

    this.mesh_quality_bars = 0;
    if (props.hasOwnProperty('mesh_quality_bars')) {
      const v = props.mesh_quality_bars;
      this.mesh_quality_bars = v;
    }

    this.wired = false;
    if (props.hasOwnProperty('wired')) {
      const v = props.wired;
      this.wired = !!v;
    }

    this.last_reboot = null;
    if (props.hasOwnProperty('last_reboot')) {
      const v = props.last_reboot;
      this.last_reboot = v && new Date(v);
    }
  }
}
export {Eero};

class EeroResponse {
  meta: ?ResponseMeta;
  data: ?Eero;

  constructor(props: $Shape<EeroResponse> = {}): void {
    if (!props) {
      props = {};
    }

    this.meta = null;
    if (props.hasOwnProperty('meta')) {
      const v = props.meta;
      this.meta = v && new ResponseMeta(v);
    }

    this.data = null;
    if (props.hasOwnProperty('data')) {
      const v = props.data;
      this.data = v && new Eero(v);
    }
  }

  getMeta(): ResponseMeta {
    if (this.meta) {
      return this.meta;
    }
    return new ResponseMeta();
  }

  getData(): Eero {
    if (this.data) {
      return this.data;
    }
    return new Eero();
  }
}
export {EeroResponse};

class SearchedEero_Network {
  url: string;
  name: string;
  created: ?Date;

  constructor(props: $Shape<SearchedEero_Network> = {}): void {
    if (!props) {
      props = {};
    }

    this.url = '';
    if (props.hasOwnProperty('url')) {
      const v = props.url;
      this.url = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }
  }
}
export {SearchedEero_Network};
class SearchedEero {
  id: string;
  serial: string;
  mac_address: string;
  model: Eero_Model;
  model_number: string;
  network: ?SearchedEero_Network;
  deactivated: boolean;

  constructor(props: $Shape<SearchedEero> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.serial = '';
    if (props.hasOwnProperty('serial')) {
      const v = props.serial;
      this.serial = v;
    }

    this.mac_address = '';
    if (props.hasOwnProperty('mac_address')) {
      const v = props.mac_address;
      this.mac_address = v;
    }

    this.model = Eero_ModelValue(0);
    if (props.hasOwnProperty('model')) {
      const v = props.model;
      this.model = v;
    }

    this.model_number = '';
    if (props.hasOwnProperty('model_number')) {
      const v = props.model_number;
      this.model_number = v;
    }

    this.network = null;
    if (props.hasOwnProperty('network')) {
      const v = props.network;
      this.network = v && new SearchedEero_Network(v);
    }

    this.deactivated = false;
    if (props.hasOwnProperty('deactivated')) {
      const v = props.deactivated;
      this.deactivated = !!v;
    }
  }

  getNetwork(): SearchedEero_Network {
    if (this.network) {
      return this.network;
    }
    return new SearchedEero_Network();
  }
}
export {SearchedEero};

class SearchedEeroResponse {
  meta: ?ResponseMeta;
  data: ?SearchedEero;

  constructor(props: $Shape<SearchedEeroResponse> = {}): void {
    if (!props) {
      props = {};
    }

    this.meta = null;
    if (props.hasOwnProperty('meta')) {
      const v = props.meta;
      this.meta = v && new ResponseMeta(v);
    }

    this.data = null;
    if (props.hasOwnProperty('data')) {
      const v = props.data;
      this.data = v && new SearchedEero(v);
    }
  }

  getMeta(): ResponseMeta {
    if (this.meta) {
      return this.meta;
    }
    return new ResponseMeta();
  }

  getData(): SearchedEero {
    if (this.data) {
      return this.data;
    }
    return new SearchedEero();
  }
}
export {SearchedEeroResponse};

class UpdateEero {
  serial: string;
  network: string;
  location: string;
  requireSession: boolean;

  constructor(props: $Shape<UpdateEero> = {}): void {
    if (!props) {
      props = {};
    }

    this.serial = '';
    if (props.hasOwnProperty('serial')) {
      const v = props.serial;
      this.serial = v;
    }

    this.network = '';
    if (props.hasOwnProperty('network')) {
      const v = props.network;
      this.network = v;
    }

    this.location = '';
    if (props.hasOwnProperty('location')) {
      const v = props.location;
      this.location = v;
    }

    this.requireSession = false;
    if (props.hasOwnProperty('requireSession')) {
      const v = props.requireSession;
      this.requireSession = !!v;
    }
  }
}
export {UpdateEero};

class DevicesResponse {
  meta: ?ResponseMeta;
  data: Array<Device>;

  constructor(props: $Shape<DevicesResponse> = {}): void {
    if (!props) {
      props = {};
    }

    this.meta = null;
    if (props.hasOwnProperty('meta')) {
      const v = props.meta;
      this.meta = v && new ResponseMeta(v);
    }

    this.data = [];
    if (props.hasOwnProperty('data')) {
      const v = props.data;
      if (!Array.isArray(v)) {
        throw new Error('repeated field data should be array');
      }
      this.data = v.map(function(v) {
        return new Device(v);
      });
    }
  }

  getMeta(): ResponseMeta {
    if (this.meta) {
      return this.meta;
    }
    return new ResponseMeta();
  }
}
export {DevicesResponse};

export const Device_ConnectionTypeValues = {
  UNKNOWN: 0,
  SONOS: 1,
  THREAD: 2,
  WIRELESS: 3,
  WIRED: 4,
};
type Device_ConnectionType = $Keys<typeof Device_ConnectionTypeValues>;
export type {Device_ConnectionType};

export function Device_ConnectionTypeValue(n: number): Device_ConnectionType {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'SONOS';

    case 2:
      return 'THREAD';

    case 3:
      return 'WIRELESS';

    case 4:
      return 'WIRED';

    default:
      return 'UNKNOWN';
  }
}

class Device_Source {
  location: string;

  constructor(props: $Shape<Device_Source> = {}): void {
    if (!props) {
      props = {};
    }

    this.location = '';
    if (props.hasOwnProperty('location')) {
      const v = props.location;
      this.location = v;
    }
  }
}
export {Device_Source};
class Device_Connectivity {
  rx_bitrate: string;
  signal: string;
  signal_avg: string;
  score_bars: number;
  score: number;

  constructor(props: $Shape<Device_Connectivity> = {}): void {
    if (!props) {
      props = {};
    }

    this.rx_bitrate = '';
    if (props.hasOwnProperty('rx_bitrate')) {
      const v = props.rx_bitrate;
      this.rx_bitrate = v;
    }

    this.signal = '';
    if (props.hasOwnProperty('signal')) {
      const v = props.signal;
      this.signal = v;
    }

    this.signal_avg = '';
    if (props.hasOwnProperty('signal_avg')) {
      const v = props.signal_avg;
      this.signal_avg = v;
    }

    this.score_bars = 0;
    if (props.hasOwnProperty('score_bars')) {
      const v = props.score_bars;
      this.score_bars = v;
    }

    this.score = 0;
    if (props.hasOwnProperty('score')) {
      const v = props.score;
      this.score = v;
    }
  }
}
export {Device_Connectivity};
class Device_Interface {
  frequency: string;
  frequency_unit: string;

  constructor(props: $Shape<Device_Interface> = {}): void {
    if (!props) {
      props = {};
    }

    this.frequency = '';
    if (props.hasOwnProperty('frequency')) {
      const v = props.frequency;
      this.frequency = v;
    }

    this.frequency_unit = '';
    if (props.hasOwnProperty('frequency_unit')) {
      const v = props.frequency_unit;
      this.frequency_unit = v;
    }
  }
}
export {Device_Interface};
class Device_Usage {
  up_mbps: number;
  down_mbps: number;

  constructor(props: $Shape<Device_Usage> = {}): void {
    if (!props) {
      props = {};
    }

    this.up_mbps = 0;
    if (props.hasOwnProperty('up_mbps')) {
      const v = props.up_mbps;
      this.up_mbps = v;
    }

    this.down_mbps = 0;
    if (props.hasOwnProperty('down_mbps')) {
      const v = props.down_mbps;
      this.down_mbps = v;
    }
  }
}
export {Device_Usage};
class Device {
  url: string;
  mac: string;
  eui64: string;
  manufacturer: string;
  ips: Array<string>;
  nickname: string;
  hostname: string;
  connected: boolean;
  wireless: boolean;
  connection_type: Device_ConnectionType;
  source: ?Device_Source;
  last_active: ?Date;
  first_active: ?Date;
  connectivity: ?Device_Connectivity;
  interface: ?Device_Interface;
  usage: ?Device_Usage;
  device_type: string;

  constructor(props: $Shape<Device> = {}): void {
    if (!props) {
      props = {};
    }

    this.url = '';
    if (props.hasOwnProperty('url')) {
      const v = props.url;
      this.url = v;
    }

    this.mac = '';
    if (props.hasOwnProperty('mac')) {
      const v = props.mac;
      this.mac = v;
    }

    this.eui64 = '';
    if (props.hasOwnProperty('eui64')) {
      const v = props.eui64;
      this.eui64 = v;
    }

    this.manufacturer = '';
    if (props.hasOwnProperty('manufacturer')) {
      const v = props.manufacturer;
      this.manufacturer = v;
    }

    this.ips = [];
    if (props.hasOwnProperty('ips')) {
      const v = props.ips;
      if (!Array.isArray(v)) {
        throw new Error('repeated field ips should be array');
      }
      this.ips = v.map(function(v) {
        return v;
      });
    }

    this.nickname = '';
    if (props.hasOwnProperty('nickname')) {
      const v = props.nickname;
      this.nickname = v;
    }

    this.hostname = '';
    if (props.hasOwnProperty('hostname')) {
      const v = props.hostname;
      this.hostname = v;
    }

    this.connected = false;
    if (props.hasOwnProperty('connected')) {
      const v = props.connected;
      this.connected = !!v;
    }

    this.wireless = false;
    if (props.hasOwnProperty('wireless')) {
      const v = props.wireless;
      this.wireless = !!v;
    }

    this.connection_type = Device_ConnectionTypeValue(0);
    if (props.hasOwnProperty('connection_type')) {
      const v = props.connection_type;
      this.connection_type = v;
    }

    this.source = null;
    if (props.hasOwnProperty('source')) {
      const v = props.source;
      this.source = v && new Device_Source(v);
    }

    this.last_active = null;
    if (props.hasOwnProperty('last_active')) {
      const v = props.last_active;
      this.last_active = v && new Date(v);
    }

    this.first_active = null;
    if (props.hasOwnProperty('first_active')) {
      const v = props.first_active;
      this.first_active = v && new Date(v);
    }

    this.connectivity = null;
    if (props.hasOwnProperty('connectivity')) {
      const v = props.connectivity;
      this.connectivity = v && new Device_Connectivity(v);
    }

    this.interface = null;
    if (props.hasOwnProperty('interface')) {
      const v = props.interface;
      this.interface = v && new Device_Interface(v);
    }

    this.usage = null;
    if (props.hasOwnProperty('usage')) {
      const v = props.usage;
      this.usage = v && new Device_Usage(v);
    }

    this.device_type = '';
    if (props.hasOwnProperty('device_type')) {
      const v = props.device_type;
      this.device_type = v;
    }
  }

  getSource(): Device_Source {
    if (this.source) {
      return this.source;
    }
    return new Device_Source();
  }

  getConnectivity(): Device_Connectivity {
    if (this.connectivity) {
      return this.connectivity;
    }
    return new Device_Connectivity();
  }

  getInterface(): Device_Interface {
    if (this.interface) {
      return this.interface;
    }
    return new Device_Interface();
  }

  getUsage(): Device_Usage {
    if (this.usage) {
      return this.usage;
    }
    return new Device_Usage();
  }
}
export {Device};

class NetworkSpeedTestResponse {
  meta: ?ResponseMeta;
  data: Array<NetworkSpeedTest>;

  constructor(props: $Shape<NetworkSpeedTestResponse> = {}): void {
    if (!props) {
      props = {};
    }

    this.meta = null;
    if (props.hasOwnProperty('meta')) {
      const v = props.meta;
      this.meta = v && new ResponseMeta(v);
    }

    this.data = [];
    if (props.hasOwnProperty('data')) {
      const v = props.data;
      if (!Array.isArray(v)) {
        throw new Error('repeated field data should be array');
      }
      this.data = v.map(function(v) {
        return new NetworkSpeedTest(v);
      });
    }
  }

  getMeta(): ResponseMeta {
    if (this.meta) {
      return this.meta;
    }
    return new ResponseMeta();
  }
}
export {NetworkSpeedTestResponse};

class NetworkSpeedTest {
  date: ?Date;
  down_mbps: number;
  up_mbps: number;

  constructor(props: $Shape<NetworkSpeedTest> = {}): void {
    if (!props) {
      props = {};
    }

    this.date = null;
    if (props.hasOwnProperty('date')) {
      const v = props.date;
      this.date = v && new Date(v);
    }

    this.down_mbps = 0;
    if (props.hasOwnProperty('down_mbps')) {
      const v = props.down_mbps;
      this.down_mbps = v;
    }

    this.up_mbps = 0;
    if (props.hasOwnProperty('up_mbps')) {
      const v = props.up_mbps;
      this.up_mbps = v;
    }
  }
}
export {NetworkSpeedTest};

class EeroNetworkStatus {
  wlans: Array<cwn_han.Wlan>;
  unifi_statuses: Array<cwn_ubnt_unifi.Status>;
  serial_numbers: Array<string>;
  mac_addresses: Array<string>;

  constructor(props: $Shape<EeroNetworkStatus> = {}): void {
    if (!props) {
      props = {};
    }

    this.wlans = [];
    if (props.hasOwnProperty('wlans')) {
      const v = props.wlans;
      if (!Array.isArray(v)) {
        throw new Error('repeated field wlans should be array');
      }
      this.wlans = v.map(function(v) {
        return new cwn_han.Wlan(v);
      });
    }

    this.unifi_statuses = [];
    if (props.hasOwnProperty('unifi_statuses')) {
      const v = props.unifi_statuses;
      if (!Array.isArray(v)) {
        throw new Error('repeated field unifi_statuses should be array');
      }
      this.unifi_statuses = v.map(function(v) {
        return new cwn_ubnt_unifi.Status(v);
      });
    }

    this.serial_numbers = [];
    if (props.hasOwnProperty('serial_numbers')) {
      const v = props.serial_numbers;
      if (!Array.isArray(v)) {
        throw new Error('repeated field serial_numbers should be array');
      }
      this.serial_numbers = v.map(function(v) {
        return v;
      });
    }

    this.mac_addresses = [];
    if (props.hasOwnProperty('mac_addresses')) {
      const v = props.mac_addresses;
      if (!Array.isArray(v)) {
        throw new Error('repeated field mac_addresses should be array');
      }
      this.mac_addresses = v.map(function(v) {
        return v;
      });
    }
  }
}
export {EeroNetworkStatus};

// END cwn/eero/models.proto
